import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from '../config/config';


function SirketSil() {
  const [deletedCompanies, setDeletedCompanies] = useState([]);

  // API'den silinen şirketleri getiriyoruz
  useEffect(() => {
    axios.get(`${config.baseUrl}/api/company-deleted`)  // isActive = false olanları al
      .then(response => {
        console.log("Silinen şirketler: ", response.data); // Gelen verileri kontrol ediyoruz
        setDeletedCompanies(response.data);
      })
      .catch(error => {
        console.error('Veriler çekilirken hata oluştu:', error);
      });
  }, []);

  // Şirketi geri getirme fonksiyonu
  const handleRestore = (id) => {
    if (window.confirm('Bu şirketi geri getirmek istediğinize emin misiniz?')) {
      axios.put(`${config.baseUrl}/api/company/restore/${id}`, { isActive: true })
        .then(() => {
          // Şirketi geri getirince listeden çıkarıyoruz
          setDeletedCompanies(deletedCompanies.filter(company => company.id !== id));
        })
        .catch(error => {
          console.error('Geri getirme hatası:', error);
        });
    }
  };

  return (
    <div className="container">
      <div className="header-with-buttons">
        <h1 className="list-header">Silinen Şirketler</h1>
        <Link to="/sirket-liste" className="btn">Şirket Listesi</Link>
      </div>

      {/* Şirketleri tablo halinde gösteriyoruz */}
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Şirket Adı</th>
            <th>Ad Soyad</th>
            <th>Ülke</th>
            <th>Şehir</th>
            <th>Adres</th>
            <th>Telefon</th>
            <th>Aksiyonlar</th>
          </tr>
        </thead>
        <tbody>
          {deletedCompanies.length === 0 ? (
            <tr>
              <td colSpan="4" style={{ textAlign: 'center' }}>Silinen şirket bulunamadı</td>
            </tr>
          ) : (
            deletedCompanies.map(company => (
              <tr key={company.id}>
                <td>{company.id}</td>
                <td>{company.name}</td>
                <td>{company.fullName}</td>
                <td>{company.ulke}</td>
                <td>{company.sehir}</td>
                <td>{company.address}</td>
                <td>{company.phone}</td>
                <td>
                  <button className="action-btn restore-btn" onClick={() => handleRestore(company.id)}>
                    Geri Getir
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
}

export default SirketSil;
