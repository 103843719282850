import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/anasayfa.css'; // Mevcut CSS dosyasını import ediyoruz
import { Link, useNavigate } from 'react-router-dom'; // useNavigate ile yönlendirme yapacağız
import config from '../config/config';


function Anasayfa() {
  const [company, setCompany] = useState([]);
  const navigate = useNavigate();

  // Ana sayfaya girişte token kontrolü
  useEffect(() => {
    const token = localStorage.getItem('token');
    
    if (!token) {
      // Token yoksa giriş sayfasına yönlendirme
      navigate('/');
    }



    // Axios ile API isteği yapıyoruz
    axios.get(`${config.baseUrl}/api/company`, {
      headers: {
        Authorization: `Bearer ${token}` }  // Token'ı header'da gönderiyoruz
    })
      .then(response => {
        console.log(response.data);
        setCompany(response.data);  // Gelen verileri state'e kaydediyoruz
      })
      .catch(error => {
        console.error('Veriler çekilirken hata oluştu:', error);
      });
  }, [navigate]);

  // Çıkış yapma fonksiyonu
  const handleLogout = () => {
    localStorage.removeItem('token'); // Token'ı siliyoruz
    navigate('/'); // Login sayfasına yönlendiriyoruz
  };

  return (
    <div className="home-container">
      <h1>Ana Sayfa</h1>
      <div className="button-container">
        <Link to="/sirket-liste" className="btn">Şirket</Link>
        <Link to="/belgeler" className="btn">Fatura</Link>
        <Link to="/hizmet-liste" className="btn">Hizmet / Ürün</Link>

        <br/>
        <button onClick={handleLogout} className="btn">Çıkış Yap</button> {/* Çıkış butonu */}
      </div>
    </div>
  );
}

export default Anasayfa;
