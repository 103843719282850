import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // useParams ile ID alacağız
import axios from 'axios';
import '../styles/forms.css';
import config from '../config/config';


function FaturaDüzenle() {
  const { id } = useParams(); // URL'den gelen fatura ID'sini alıyoruz
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    carNo: '',
    scenario: '',
    tip: '',
    no: '',
    date: '',
    ETTN: '',
    qr: '',
    not: '',
    invoicerID: '',
    customerID: ''
  });

  const [company, setCompany] = useState([]);

  // Sayfa yüklendiğinde mevcut fatura bilgilerini ve şirket listesini çekiyoruz
  useEffect(() => {
    // Mevcut fatura bilgilerini çekiyoruz
    axios.get(`${config.baseUrl}/api/invoices/${id}`)
      .then(response => {
        setFormData(response.data); // Gelen fatura verilerini formda gösteriyoruz
      })
      .catch(error => {
        console.error('Fatura bilgileri alınırken hata oluştu:', error);
      });

    // Şirket listesini çekiyoruz
    axios.get(`${config.baseUrl}/api/company`)
      .then(response => {
        setCompany(response.data); // Şirket listesini kaydediyoruz
      })
      .catch(error => {
        console.error('Şirket verileri alınırken hata oluştu:', error);
      });
  }, [id]);

  // Formdaki değişiklikleri yönetiyoruz
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleCancel = () => {
    navigate('/belgeler');
  };

  // Formu gönderme işlemi
  const handleSubmit = (e) => {
    e.preventDefault();
    axios.put(`${config.baseUrl}/api/invoices/${id}`, formData) // PUT isteğiyle güncellenmiş verileri gönderiyoruz
      .then(() => {
        alert('Fatura başarıyla güncellendi');
        navigate('/belgeler'); // Güncelleme sonrası listeye yönlendiriyoruz
      })
      .catch(error => {
        console.error('Fatura güncellenirken hata oluştu:', error);
      });
  };

  return (
    <div className="container mt-5">
      <h1>Fatura Düzenleme Formu</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="ozellestirmeNo" className="form-label">Özelleştirme No</label>
          <input
            type="text"
            className="form-control"
            id="ozellestirmeNo"
            name="carNo"
            value={formData.carNo}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="senaryo" className="form-label">Senaryo</label>
          <input
            type="text"
            className="form-control"
            id="senaryo"
            name="scenario"
            value={formData.scenario}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="faturaTipi" className="form-label">Fatura Tipi</label>
          <input
            type="text"
            className="form-control"
            id="faturaTipi"
            name="tip"
            value={formData.tip}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="faturaNo" className="form-label">Fatura No</label>
          <input
            type="text"
            className="form-control"
            id="faturaNo"
            name="no"
            value={formData.no}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="faturaTarihi" className="form-label">Fatura Tarihi</label>
          <input
            type="datetime-local"
            className="form-control"
            id="faturaTarihi"
            name="date"
            value={formData.date}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="ettnNo" className="form-label">ETTN No</label>
          <input
            type="text"
            className="form-control"
            id="ettnNo"
            name="ETTN"
            value={formData.ETTN}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="qrKod" className="form-label">QR Kod</label>
          <input
            type="text"
            className="form-control"
            id="qrKod"
            name="qr"
            value={formData.qr}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="not" className="form-label">Not</label>
          <textarea
            className="form-control"
            id="not"
            name="not"
            value={formData.not}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="faturaKesenId" className="form-label">Fatura Kesen ID</label>
          <select
            className="form-control"
            id="faturaKesenId"
            name="invoicerID"
            value={formData.invoicerID}
            onChange={handleChange}
            required
          >
            <option value="">Fatura Kesen Seçin</option>
            {company.map(item => (
              <option value={item.id} key={item.id}>{item.name}</option>
            ))}
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="musteriId" className="form-label">Müşteri ID</label>
          <select
            className="form-control"
            id="musteriId"
            name="customerID"
            value={formData.customerID}
            onChange={handleChange}
            required
          >
            <option value="">Müşteri Seçin</option>
            {company.map(item => (
              <option value={item.id} key={item.id}>{item.name}</option>
            ))}
          </select>
        </div>

        <button type="submit" className="btn custom-btn" style={{ marginRight: '10px' }}>Bilgileri Kaydet</button>
        <button type="button" className="btn btn-secondary" onClick={handleCancel} style={{ backgroundColor: 'red', color: 'white' }}>Vazgeç</button>
      </form>
    </div>
  );
}

export default FaturaDüzenle;
