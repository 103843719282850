import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // useNavigate ile yönlendirme yapacağız
import '../styles/forms.css'; // form.css dosyasını import ediyoruz
import axios from 'axios';
import config from '../config/config';


function Login() {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const [error, setError] = useState(''); // Hata mesajı durumu
  const navigate = useNavigate(); // useNavigate hook'u yönlendirme işlemi için kullanılıyor

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post(`${config.baseUrl}/api/login`, formData)
      .then(response => {
        console.log('Burada1');
        if (response.data.token) {
          // Token'ı localStorage'a kaydediyoruz
          console.log('Burada2');

          localStorage.setItem('token', response.data.token);
          console.log('Burada3');

          // Giriş başarılıysa anasayfaya yönlendir
          setTimeout(() => {
            navigate('/anasayfa');
          }, 250);
          console.log('Burada4');

        } else {
          console.log('Burada5');

          setError('Hatalı şifre ya da mail adresi'); // Giriş hatası varsa hata mesajını ayarla
        }
      })
      .catch(error => {
        console.error('Giriş hatası:', error);
        setError('Giriş sırasında bir hata oluştu.'); // Bir hata oluştuğunda genel hata mesajı
      });
  };




  return (
    <div className="container">
      <div style={{ textAlign: 'center' }}> {/* Başlık ortalandı */}
        <h1>Giriş Yap</h1>
      </div>
      <form
        onSubmit={handleSubmit}
        style={{
          maxWidth: '500px', /* Form genişliğini daralttık */
          margin: '0 auto' /* Ortalamak için margin ayarı */
        }}
      >
        <div className="mb-3">
          <label htmlFor="email" className="form-label">Mail Adresi</label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="password" className="form-label">Parola</label>
          <input
            type="password"
            className="form-control"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </div>

        {/* Hata mesajı */}
        {error && <div style={{ color: 'red', marginBottom: '15px' }}>{error}</div>}

        <button type="submit" className="custom-btn">Giriş Yap</button>
      </form>

      {/* Kayıt Ol butonu formun altına alındı */}
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <Link to="/register" className="btn" style={{ height: 'fit-content' }}>Kayıt Ol</Link>
      </div>

      {/* Şifreni mi unuttun butonu */}
      <div style={{ textAlign: 'center', marginTop: '10px' }}>
        <Link to="/forget-password" className="btn" style={{ height: 'fit-content' }}>Şifreni mi unuttun?</Link>
      </div>
    </div>
  );
}

export default Login;
