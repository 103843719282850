import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';  // useNavigate ile yönlendirme yapacağız
import '../styles/forms.css';  // form.css dosyasını import ediyoruz
import config from '../config/config';


function ForgetPassword() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(''); // Hata mesajı durumu
  const navigate = useNavigate(); // Yönlendirme işlemi için

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${config.baseUrl}/api/forgot-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      if (response.ok) {
        alert('Şifre sıfırlama e-postası gönderildi.');
      } else {
        setError('Şifre sıfırlama başarısız: ' + data.message);
      }
    } catch (error) {
      console.error('Hata:', error);
      setError('Bir hata oluştu');
    }
  };


  const handleLoginClick = () => {
    navigate('/');  // "/" yoluna yönlendirme
  };

  return (
    <div className="container">
      <div style={{ textAlign: 'center' }}> {/* Başlık ortalandı */}
        <h1>Şifreyi Sıfırla</h1>
      </div>
      <form
        onSubmit={handleSubmit}
        style={{
          maxWidth: '500px', /* Form genişliği ayarlandı */
          margin: '0 auto' /* Ortalamak için margin ayarı */
        }}
      >
        <div className="mb-3">
          <label htmlFor="email" className="form-label">Mail Adresi</label>
          <input
            type="email"
            className="form-control"
            id="email"
            placeholder="Mail adresi"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        {/* Hata mesajı */}
        {error && <div style={{ color: 'red', marginBottom: '15px' }}>{error}</div>}

        <button type="submit" className="custom-btn">Mail Gönder</button>
      </form>

      {/* Giriş Yap butonu formun altına alındı */}
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <button onClick={handleLoginClick} className="btn custom-btn">Giriş Yap</button>
      </div>
    </div>
  );
}

export default ForgetPassword;
