import React, { useState, useEffect } from 'react';
import '../styles/forms.css'; // Mevcut CSS dosyasını import ediyoruz
import { Link, useNavigate } from 'react-router-dom'; // useNavigate'i import ediyoruz
import axios from 'axios';
import config from '../config/config';


function FaturaEkle() {
  const [formData, setFormData] = useState({});
  const [error, setError] = useState(''); // Hata mesajı için state

  const navigate = useNavigate(); // Yönlendirme için useNavigate'i tanımlıyoruz

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Fatura kesen ve müşteri aynı mı kontrol et
    if (formData.invoicerID === formData.customerID) {
      setError('Fatura kesen ve müşteri aynı olamaz.');
      alert('Fatura kesen ve müşteri aynı olamaz.');
      return; // Formu gönderme
    }

    console.log('Form verileri:', formData);
    sendData(formData);
  };


  function sendData(formData) {
    axios.post(`${config.baseUrl}/api/create-invoice`, formData)
      .then(response => {
        console.log(response);
        navigate('/belgeler'); // Başarılı yanıt geldiğinde Belgeler sayfasına yönlendiriyoruz
      })
      .catch(error => {
        console.error('Veri gönderme hatası:', error);
      });
  }

  const [company, setCompany] = useState([]);

  useEffect(() => {
    // Axios ile API isteği yapıyoruz
    axios.get(`${config.baseUrl}/api/company`)  // Backend API yoluna istek
      .then(response => {
        const activeCompanies = response.data.filter(item => item.isActive); // Sadece isActive true olanları filtrele
        setCompany(activeCompanies);  // Filtrelenmiş verileri state'e kaydediyoruz
      })
      .catch(error => {
        console.error('Veriler çekilirken hata oluştu:', error);
      });
  }, []);

  return (
    <div className="container mt-5">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h1>Fatura Ekleme Formu</h1>
        <div>
          <Link to="/belgeler" className="btn me-2" style={{ height: 'fit-content' }}>Listele</Link>
          <Link to="/anasayfa" className="btn" style={{ height: 'fit-content' }}>Ana Sayfa</Link>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="ozellestirmeNo" className="form-label">Özelleştirme No</label>
          <input
            type="text"
            className="form-control"
            id="ozellestirmeNo"
            name="carNo"
            value={formData.carNo}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="senaryo" className="form-label">Senaryo</label>
          <input
            type="text"
            className="form-control"
            id="senaryo"
            name="scenario"
            value={formData.scenario}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="faturaTipi" className="form-label">Fatura Tipi</label>
          <select
            className="form-control"
            id="faturaTipi"
            name="tip"
            value={formData.tip}
            onChange={handleChange}
            required
          >
            <option value="">Fatura Tipi Seçin</option>
            <option value="TEMEL">TEMEL</option>
            <option value="TİCARİ">TİCARİ</option>
            <option value="YOLCU BERABERİ">YOLCU BERABERİ</option>
            <option value="İHRACAT">İHRACAT</option>
            <option value="SATIŞ">SATIŞ</option>
            <option value="TEVKİFAT">TEVKİFAT</option>
            <option value="İSTİSNA">İSTİSNA</option>
            <option value="ÖZEL MATRAH">ÖZEL MATRAH</option>
            <option value="İHRAÇ KAYITLI">İHRAÇ KAYITLI</option>
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="faturaNo" className="form-label">Fatura No</label>
          <input
            type="text"
            className="form-control"
            id="faturaNo"
            name="no"
            value={formData.no}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="faturaTarihi" className="form-label">Fatura Tarihi</label>
          <input
            type="datetime-local"
            className="form-control"
            id="faturaTarihi"
            name="date"
            value={formData.date}
            onChange={handleChange}
            required
            onInput={(e) => {
              const year = e.target.value.split('-')[0]; // Yıl kısmını alır
              if (year.length > 4) {
                e.target.value = e.target.value.replace(year, year.slice(0, 4)); // Yılı 4 haneyle sınırla
              }
            }}
          />
        </div>





        <div className="mb-3">
          <label htmlFor="ettnNo" className="form-label">ETTN No</label>
          <input
            type="text"
            className="form-control"
            id="ettnNo"
            name="ETTN"
            value={formData.ETTN}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="qrKod" className="form-label">QR Kod</label>
          <input
            type="text"
            className="form-control"
            id="qrKod"
            name="qr"
            value={formData.qr}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="not" className="form-label">Not</label>
          <textarea
            className="form-control"
            id="not"
            name="not"
            value={formData.not}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
        <label htmlFor="faturaKesenId" className="form-label">Fatura Kesen ID</label>
        <select
          className="form-control"
          id="faturaKesenId"
          name="invoicerID"
          value={formData.invoicerID}
          onChange={handleChange}
          required
        >
          <option value="">Fatura Kesen Seçin</option>
          {company
            .map(item => (
              <option key={item.id} value={item.id}>{item.name}</option>
            ))}
        </select>
      </div>

      {/* Müşteri */}
      <div className="mb-3">
        <label htmlFor="musteriId" className="form-label">Müşteri ID</label>
        <select
          className="form-control"
          id="musteriId"
          name="customerID"
          value={formData.customerID}
          onChange={handleChange}
          required
        >
          <option value="">Müşteri Seçin</option>
          {company
            .map(item => (
              <option key={item.id} value={item.id}>{item.name}</option>
            ))}
        </select>
      </div>

        <button type="submit" className="btn custom-btn">Bilgileri Kaydet</button>
      </form>
    </div>
  );
}

export default FaturaEkle;
