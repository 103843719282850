/* const config = {
    baseUrl: process.env.REACT_APP_BASE_URL
  };
  
  export default config; */

  const config = {
    baseUrl: "https://fback.actnatie.com"
  };
  
  export default config;