import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import '../styles/belge.css'
import config from '../config/config';


function Biten() {

    const { id } = useParams();
    console.log(id);
    

    const formatDate = (dateString) => {
        const date = new Date(dateString);

        const day = String(date.getDate()).padStart(2, '0'); // Gün
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Ay
        const year = date.getFullYear(); // Yıl

        const hours = String(date.getHours()).padStart(2, '0'); // Saat
        const minutes = String(date.getMinutes()).padStart(2, '0'); // Dakika

        // İstediğiniz formatta birleştirme
        return `${day}-${month}-${year} ${hours}:${minutes}`;
    };

    const [datas, setDatas] = useState([]);

    let toplamHizmetTutar = 0;
    datas?.contentData?.map(contentData => {
        toplamHizmetTutar += parseFloat(contentData.quantity || 0) * parseFloat(contentData.unitPrice || 0) ; // totalPrice değerlerini ekle
        return null; // map'te return değeri döndürmediğimiz için null döndürüyoruz
    });

    let toplamIskonto = 0;
    datas?.contentData?.map(contentData => {
        toplamIskonto += parseFloat(contentData.discountAmount || 0); // totalPrice değerlerini ekle
        return null; // map'te return değeri döndürmediğimiz için null döndürüyoruz
    });

    let toplamVergi = 0;
    datas?.contentData?.map(contentData => {
        toplamVergi += parseFloat(contentData.taxPrice || 0) + parseFloat(contentData.otherTax || 0) ; // totalPrice değerlerini ekle
        return null; // map'te return değeri döndürmediğimiz için null döndürüyoruz
    });

    let toplamVergilerDahil = 0;
    datas?.contentData?.map(contentData => {
        toplamVergilerDahil = toplamHizmetTutar + toplamVergi;
        return null; // map'te return değeri döndürmediğimiz için null döndürüyoruz
    });

    let toplamVergilerIskontolarDahil = 0;
    datas?.contentData?.map(contentData => {
        toplamVergilerIskontolarDahil = toplamHizmetTutar + toplamVergi + toplamIskonto;
        return null; // map'te return değeri döndürmediğimiz için null döndürüyoruz
    });


    useEffect(() => {

        const params = {
            id: id
        };

        // API isteği ile şirket verilerini çekiyoruz
        axios.get(`${config.baseUrl}/api/finalized-invoice/${id}`)
            .then(response => {
                console.log("---------------");
                console.log(response.data);
                setDatas(response.data); // Gelen verileri state'e kaydediyoruz
            })
            .catch(error => {
                console.error('Veriler çekilirken hata oluştu:', error);
            });
    }, [id]);




    return (
        <>
            <div className="belge-page">
                <div className="main">
                    <div className="content-company">
                        <div className="company-row">
                            <hr className="stdhr" />
                            <p className="company-text">{datas?.companyData?.invoicer?.name}</p>
                            <p className="company-text">{datas?.companyData?.invoicer?.address}</p>
                            <p className="company-text">Tel: {datas?.companyData?.invoicer?.phone} Fax: </p>
                            <p className="company-text">Web Page: {datas?.companyData?.invoicer?.website}</p>
                            <p className="company-text">Email: {datas?.companyData?.invoicer?.email}</p>
                            <p className="company-text">Tax Office: {datas?.companyData?.invoicer?.taxOffice}</p>
                            <p className="company-text">Turkiye Republic Citizen ID: {datas?.companyData?.invoicer?.TC}</p>

                            <hr className="stdhr" />
                        </div>

                        <div className="company-row">
                            <hr className="stdhr" />
                            <h4 className="dear">DEAR</h4>
                            <p className="company-text">{datas?.companyData?.customer?.name}</p>
                            <p className="company-text">{datas?.companyData?.customer?.address}</p>
                            <p className="company-text">Tel: {datas?.companyData?.customer?.phone} Fax: </p>
                            <p className="company-text">Web Page: {datas?.companyData?.customer?.website}</p>
                            <p className="company-text">Email: {datas?.companyData?.customer?.email}</p>
                            <p className="company-text">Tax Office: {datas?.companyData?.customer?.taxOffice}</p>
                            <p className="company-text">Turkiye Republic Citizen ID: {datas?.companyData?.customer?.TC}</p>
                            <hr className="stdhr" />
                            <h4 className="dear" style={{ margin: '0px', display: 'inline' }}>ETTN:</h4><span
                                className="company-text">{datas?.invoiceData?.ETTN}</span>

                        </div>
                    </div>
                    <div className="invoice-img-row">
                        <div className="invoice-content">
                            {/* burada img */}
                        </div>
                        <p className="invoice-name">e-Arşiv Fatura</p>
                    </div>
                    <div className="empty"></div>
                    <div className="qrandinfo">
                        <div style={{ width: '220px', height: '220px', backgroundColor: 'red', marginLeft: 'auto' }}>

                        </div>
                        <div style={{ height: '178px', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                            <table>
                                <tr>
                                    <td className="invoicedata-tablebody">Corporatization No:</td>
                                    <td className="invoicedata-tabletext">{datas?.invoiceData?.carNo}</td>
                                </tr>
                                <tr>
                                    <td className="invoicedata-tablebody">Scenario:</td>
                                    <td className="invoicedata-tabletext">{datas?.invoiceData?.scenario}</td>
                                </tr>
                                <tr>
                                    <td className="invoicedata-tablebody">Invoice Type:</td>
                                    <td className="invoicedata-tabletext">{datas?.invoiceData?.tip}</td>
                                </tr>
                                <tr>
                                    <td className="invoicedata-tablebody">Invoice No:</td>
                                    <td className="invoicedata-tabletext">{datas?.invoiceData?.no}</td>
                                </tr>
                                <tr>
                                    <td className="invoicedata-tablebody">Invoice Date:</td>
                                    <td className="invoicedata-tabletext">{formatDate(datas?.invoiceData?.date)}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>

                <div style={{ width: '800px', marginLeft: '5px', marginTop: '26px' }}>
                    <div>
                        <table className="table-main">
                            <tbody>
                                <tr>
                                    <td style={{ width: '3%', textAlign: 'center' }} className="ctable-header">QTY</td>
                                    <td style={{ width: '20%', textAlign: 'center' }} className="ctable-header">Description</td>
                                    <td style={{ width: '7.4%', textAlign: 'center' }} className="ctable-header">Quantity</td>
                                    <td style={{ width: '9%', textAlign: 'center' }} className="ctable-header">Unit Price</td>
                                    <td style={{ width: '7%', textAlign: 'center' }} className="ctable-header">Discount/ Increase Percantage</td>
                                    <td style={{ width: '9%', textAlign: 'center' }} className="ctable-header">Discount/ Increase Price</td>
                                    <td style={{ width: '9%', textAlign: 'center' }} className="ctable-header">Discount/ Increase Reason</td>
                                    <td style={{ width: '7%', textAlign: 'center' }} className="ctable-header">Tax Percentage</td>
                                    <td style={{ width: '10%', textAlign: 'center' }} className="ctable-header">Tax Price</td>
                                    <td style={{ width: '17%', textAlign: 'center' }} className="ctable-header">Other Tax</td>
                                    <td style={{ width: '10.6%', textAlign: 'center' }} className="ctable-header">Total Price</td>
                                    <td style={{ width: '10.6%', textAlign: 'center' }} className="ctable-header">GTİP</td>
                                </tr>
                            </tbody>
                            {datas?.contentData?.map((contentData, index) => (
                                <tr key={index} style={{ height: '16px' }}>
                                    <td className="ctable-contetn">{index + 1}</td> {/* 1'den başlayan sıra numarası */}
                                    <td className="ctable-contetn">{contentData.name}</td>
                                    <td className="ctable-contetn">{contentData.quantity}</td>
                                    <td className="ctable-contetn">{contentData.unitPrice}</td>
                                    <td className="ctable-contetn">{contentData.discountRate}</td>
                                    <td className="ctable-contetn">{contentData.discountAmount}</td>
                                    <td className="ctable-contetn">{contentData.discountIR}</td>
                                    <td className="ctable-contetn">{contentData.taxPercentage}</td>
                                    <td className="ctable-contetn">{contentData.taxPrice}</td>
                                    <td className="ctable-contetn">{contentData.otherTax}</td>
                                    <td className="ctable-contetn">{contentData.totalPrice}</td>
                                    <td className="ctable-contetn">{contentData.GTIP}</td>
                                </tr>
                            ))}

                            {/* Toplam 20 satır olacak şekilde boş satırları ekliyoruz */}
                            {Array.from({ length: 20 - (datas?.contentData?.length || 0) }).map((_, index) => (
                                <tr key={`empty-${index}`} style={{ height: '16px' }}>
                                    <td className="ctable-contetn"></td>
                                    <td className="ctable-contetn"></td>
                                    <td className="ctable-contetn"></td>
                                    <td className="ctable-contetn"></td>
                                    <td className="ctable-contetn"></td>
                                    <td className="ctable-contetn"></td>
                                    <td className="ctable-contetn"></td>
                                    <td className="ctable-contetn"></td>
                                    <td className="ctable-contetn"></td>
                                    <td className="ctable-contetn"></td>
                                    <td className="ctable-contetn"></td>
                                    <td className="ctable-contetn"></td>
                                </tr>
                            ))}
                        </table>
                    </div>
                </div >

                <div style={{ width: '800px', marginLeft: '5px', display: 'flex', justifyContent: 'flex-end' }}>
                    <table className="total-table">
                        <tbody>
                            <tr>
                                <td className="total-table-header" style={{ width: '200px' }}>Job And Service Total Price</td>
                                <td className="total-table-content">{toplamHizmetTutar} EUR</td>
                            </tr>
                            <tr>
                                <td className="total-table-header" style={{ width: '200px' }}>Total Discount</td>
                                <td className="total-table-content">{toplamIskonto} EUR</td>
                            </tr>
                            <tr>
                                <td className="total-table-header" style={{ width: '200px' }}>Tax (%0)</td>
                                <td className="total-table-content">{toplamVergi} EUR</td>
                            </tr>
                            <tr>
                                <td className="total-table-header" style={{ width: '200px' }}>Includeing Taxes Total Price</td>
                                <td className="total-table-content">{toplamVergilerDahil} EUR</td>
                            </tr>
                            <tr>
                                <td className="total-table-header" style={{ width: '200px' }}>Amount Payable</td>
                                <td className="total-table-content">{toplamVergilerIskontolarDahil} EUR</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div style={{ width: '800px', marginLeft: '3px', border: '2px solid #000', marginTop: '26px' }}>
                    <div className="not-main">
                        <span className="not">Not:</span>
                        <span className="notcontent">{datas?.invoiceData?.not}</span>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Biten;
