import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom'; // useNavigate'i ekledik
import '../styles/list.css'; // Ortak CSS dosyasını import ediyoruz
import config from '../config/config';


function SirketList() {
  const [companies, setCompanies] = useState([]);
  const navigate = useNavigate(); // Navigasyonu kullanmak için

  useEffect(() => {
    axios.get(`${config.baseUrl}/api/company`)
      .then(response => {
        console.log(response.data);
        // isActive alanı true olanları filtreleyerek state'e kaydediyoruz
        const activeCompanies = response.data.filter(company => company.isActive === true);
        setCompanies(activeCompanies);
      })
      .catch(error => {
        console.error('Veriler çekilirken hata oluştu:', error);
      });
  }, []);

  const handleEdit = (id) => {
    navigate(`/sirket-duzenle/${id}`); // Düzenleme sayfasına yönlendirir
  };

  const handleDelete = (id) => {
    if (window.confirm('Bu şirketi silmek istediğinizden emin misiniz?')) {
      axios.put(`${config.baseUrl}/api/company/deactivate/${id}`)
        .then(() => {
          alert('Şirket başarıyla silindi');
          setCompanies(companies.filter(company => company.id !== id)); // Listeyi güncelliyoruz
        })
        .catch(error => {
          console.error('Şirket silinirken hata oluştu:', error);
        });
    }
  };

  return (
    <div className="container">
      <div className="header-with-buttons">
        <h1 className="list-header">Şirket Listesi</h1>
        <div className="button-group">
          <Link to="/sirket-sil" className="btn">Silinenler</Link>
          <Link to="/sirket-ekle" className="btn">Şirket Ekle</Link>
          <Link to="/anasayfa" className="btn">Ana Sayfa</Link>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Şirket</th>
            <th>Ad Soyad</th>
            <th>Adres</th>
            <th>Telefon</th>
            <th>Website</th>
            <th>E-Mail</th>
            <th>Vergi Dairesi</th>
            <th>TC</th>
            <th>Şehir</th>
            <th>Ülke</th>
            <th>Aksiyonlar</th>
          </tr>
        </thead>
        <tbody>
          {companies.map(company => (
            <tr key={company.id}>
              <td>{company.id}</td>
              <td>{company.name}</td>
              <td>{company.fullName}</td>
              <td>{company.address}</td>
              <td>{company.phone}</td>
              <td>{company.website}</td>
              <td>{company.email}</td>
              <td>{company.taxOffice}</td>
              <td>{company.TC}</td>
              <td>{company.sehir}</td>
              <td>{company.ulke}</td>
              <td>
                <button className="action-btn edit-btn" onClick={() => handleEdit(company.id)}>Düzenle</button>
                <button className="action-btn delete-btn" onClick={() => handleDelete(company.id)}>Sil</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default SirketList;
