import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // useParams ile ID alacağız
import axios from 'axios';
import '../styles/forms.css';
import config from '../config/config';


function HizmetDüzenle() {
  const { id } = useParams(); // URL'den ID'yi alıyoruz
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    quantity: '',
    unitPrice: '',
    discountRate: '',
    discountAmount: '',
    discountIR: '',
    taxPercentage: '',
    taxPrice: '',
    otherTax: '',
    totalPrice: '',
    GTIP: '',
    invoiceID: ''
  });

  // Sayfa yüklendiğinde mevcut hizmet bilgilerini çekiyoruz
  useEffect(() => {
    axios.get(`${config.baseUrl}/api/contents/${id}`) // Belirli hizmeti çekiyoruz
      .then(response => {
        console.log (response.data);
        setFormData(response.data); // Gelen verileri formda gösteriyoruz
      })
      .catch(error => {
        console.error('Hizmet bilgileri alınırken hata oluştu:', error);
      });
  }, [id]); // ID her değiştiğinde bu işlemi yap

  // Formdaki değişiklikleri yönetiyoruz
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleCancel = () => {
    navigate('/hizmet-liste');
  };

  // Formu gönderme işlemi
  const handleSubmit = (e) => {
    e.preventDefault();
    axios.put(`${config.baseUrl}/api/contents/${id}`, formData) // PUT isteğiyle güncellenmiş verileri gönderiyoruz
      .then(() => {
        alert('Hizmet başarıyla güncellendi');
        navigate('/hizmet-liste'); // Güncelleme sonrası listeye yönlendiriyoruz
      })
      .catch(error => {
        console.error('Hizmet güncellenirken hata oluştu:', error);
      });
  };

  return (
    <div className="container mt-5">
      <h1>Hizmet Düzenleme Formu</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">Hizmet Adı</label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            value={formData.name} // Mevcut veriyi formda göster
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="quantity" className="form-label">Adet</label>
          <input
            type="number"
            className="form-control"
            id="quantity"
            name="quantity"
            value={formData.quantity}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="unitPrice" className="form-label">Adet Fiyatı</label>
          <input
            type="number"
            className="form-control"
            id="unitPrice"
            name="unitPrice"
            value={formData.unitPrice}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="discountRate" className="form-label">İskonto Oranı (%)</label>
          <input
            type="number"
            className="form-control"
            id="discountRate"
            name="discountRate"
            value={formData.discountRate}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="discountAmount" className="form-label">İskonto Tutarı</label>
          <input
            type="number"
            className="form-control"
            id="discountAmount"
            name="discountAmount"
            value={formData.discountAmount}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="discountIR" className="form-label">İskonto Artırım Nedeni</label>
          <input
            type="text"
            className="form-control"
            id="discountIR"
            name="discountIR"
            value={formData.discountIR}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="taxPercentage" className="form-label">KDV Oranı (%)</label>
          <input
            type="number"
            className="form-control"
            id="taxPercentage"
            name="taxPercentage"
            value={formData.taxPercentage}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="taxPrice" className="form-label">KDV Tutarı</label>
          <input
            type="number"
            className="form-control"
            id="taxPrice"
            name="taxPrice"
            value={formData.taxPrice}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="otherTax" className="form-label">Diğer Vergiler</label>
          <input
            type="number"
            className="form-control"
            id="otherTax"
            name="otherTax"
            value={formData.otherTax}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="totalPrice" className="form-label">Toplam Tutar</label>
          <input
            type="number"
            className="form-control"
            id="totalPrice"
            name="totalPrice"
            value={formData.totalPrice}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="GTIP" className="form-label">GTIP</label>
          <input
            type="text"
            className="form-control"
            id="GTIP"
            name="GTIP"
            value={formData.GTIP}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="invoiceID" className="form-label">Fatura ID</label>
          <input
            type="number"
            className="form-control"
            id="invoiceID"
            name="invoiceID"
            value={formData.invoiceID}
            onChange={handleChange}
            required
          />
        </div>

        <button type="submit" className="btn custom-btn" style={{ marginRight: '10px' }}>Bilgileri Kaydet</button>
        <button type="button" className="btn btn-secondary" onClick={handleCancel} style={{ backgroundColor: 'red', color: 'white' }}>Vazgeç</button>
      </form>
    </div>
  );
}

export default HizmetDüzenle;
