import React, { useState } from 'react';
import '../styles/forms.css'; // Mevcut CSS dosyasını import ediyoruz
import { Link, useNavigate } from 'react-router-dom'; // useNavigate'i ekliyoruz
import axios from 'axios';
import countryCityData from "../asets/countryCityData.json";  // JSON dosyasını içe aktar
import config from '../config/config';



function SirketEkle() {
  const [formData, setFormData] = useState({});
  const navigate = useNavigate(); // Yönlendirme için useNavigate'i kullanıyoruz
  const [companyType, setCompanyType] = useState("");  // Şirket türünü tutmak için


  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const [selectedCountry, setSelectedCountry] = useState(""); // Seçilen ülke
  const [cities, setCities] = useState([]); // Şehirler


  const handleCountryChange = (e) => {
    const ulke = e.target.value;
    setSelectedCountry(ulke); // Seçilen ülkeyi güncelle
    setCities(countryCityData[ulke] || []); // Şehirleri güncelle
    setFormData({
      ...formData,
      ulke: ulke,  // FormData'ya seçilen ülkeyi ekliyoruz (Veritabanındaki "ulke" sütunu)
      sehir: "" // Ülke değiştiğinde şehir alanını sıfırlıyoruz
    });
  };


  const handleCityChange = (e) => {
    setFormData({
      ...formData,
      sehir: e.target.value // FormData'ya seçilen şehri ekliyoruz
    });
  };

  const handleCompanyTypeChange = (e) => {
    const type = e.target.value;
    setCompanyType(type); // Şirket türünü state'e kaydet
    handleChange(e); // Şirket türünü formData'ya ekle
  };

  const handleSubmit = (e) => {
    e.preventDefault();

  // TC numarası kontrolü
  if (formData.TC && formData.TC.length !== 11) {
    alert('TC No tam olarak 11 hane olmalıdır.');
    return; // Form gönderilmez
  }

    console.log('Form verileri:', formData);
    sendData(formData);
  };



  function sendData(formData) {
    axios.post(`${config.baseUrl}/api/create-company`, formData)
      .then(response => {
        console.log(response);
        navigate('/sirket-liste'); // Şirket eklendikten sonra liste sayfasına yönlendiriyoruz
      })
      .catch(error => {
        console.error('Şirket eklenirken hata oluştu:', error);
      });
  }

  return (
    <div className="container mt-5">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h1>Şirket Ekleme Formu</h1>
        <div>
          <Link to="/sirket-liste" className="btn me-2" style={{ height: 'fit-content' }}>Listele</Link>
          <Link to="/anasayfa" className="btn" style={{ height: 'fit-content' }}>Ana Sayfa</Link>
        </div>
      </div>

      <form onSubmit={handleSubmit}>


        <div className="mb-3">
          <label htmlFor="faturaTipi" className="form-label">Şirket Türü</label>
          <select
            className="form-control"
            id="sirketTipi"
            name="cType"
            value={formData.cType}
            onChange={handleCompanyTypeChange}
            required
          >
            <option value="">Şirket Türü Seçin</option>
            <option value="Bireysel">Bireysel</option>
            <option value="Şahıs">Şahıs</option>
            <option value="Limited">Limited</option>
            <option value="Anonim">Anonim</option>
          </select>
        </div>



        {companyType === "Limited" || companyType === "Anonim" ? (
          <>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">Şirket Adı</label>
              <input
                type="text"
                className="form-control"
                id="sirketAdi"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="ticariSicilNo" className="form-label">Ticari Sicil No</label>
              <input
                type="number"
                className="form-control"
                id="ticariSicilNo"
                name="TC" // Aynı alan kullanılıyor
                value={formData.TC || ""}
                onChange={handleChange}
                required
              />
            </div>
          </>
        ) : (
          <>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">Ad Soyad</label>
              <input
                type="text"
                className="form-control"
                id="adSoyad"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="tcNo" className="form-label">TC No</label>
              <input
                type="number"
                className="form-control"
                id="tcNo"
                name="TC"
                value={formData.TC || ""} // Eğer TC undefined ise boş string göster
                onChange={(e) => {
                  const value = e.target.value;
                  if (value.length <= 11) {
                    setFormData({ ...formData, TC: value });
                  }
                }}
                required
              />
              {/* 11 haneli kontrolü */}
              {formData.TC && formData.TC.length !== 11 && (
                <p style={{ color: 'red' }}>TC No tam olarak 11 hane olmalıdır.</p>
              )}
            </div>
          </>
        )}



        <div className="mb-3">
          <label htmlFor="ulke" className="form-label">Ülke</label>
          <select
            className="form-control"
            id="ulke"
            name="ulke"
            value={selectedCountry}
            onChange={handleCountryChange}
            required
          >
            <option value="">Ülke Seçin</option>
            {Object.keys(countryCityData).map((ulke) => (
              <option key={ulke} value={ulke}>
                {ulke}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="sehir" className="form-label">Şehir</label>
          <select
            className="form-control"
            id="sehir"
            name="sehir"
            value={formData.sehir || ""}
            onChange={handleCityChange}
            disabled={!selectedCountry} // Ülke seçilmeden şehir seçilemesin
            required
          >
            <option value="">Şehir Seçin</option>
            {cities.map((sehir) => (
              <option key={sehir} value={sehir}>
                {sehir}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="adres" className="form-label">Adres</label>
          <input
            type="text"
            className="form-control"
            id="adres"
            name="address"
            value={formData.address}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="phone" className="form-label">Telefon No</label>
          <input
            type="number"  // Sayı girişi için
            className="form-control"
            id="telefonNo"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            step="1"  // Yalnızca tam sayılar kabul edilir
            required
          />
        </div>


        <div className="mb-3">
          <label htmlFor="website" className="form-label">Website</label>
          <input
            type="url"
            className="form-control"
            id="website"
            name="website"
            value={formData.website}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="email" className="form-label">E-posta Adresi</label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="vergiDairesi" className="form-label">Vergi Dairesi</label>
          <input
            type="text"
            className="form-control"
            id="vergiDairesi"
            name="taxOffice"
            value={formData.taxOffice}
            onChange={handleChange}
            required
          />
        </div>












        <button type="submit" className="btn custom-btn">Bilgileri Kaydet</button>
      </form>
    </div>
  );
}

export default SirketEkle;
