import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // useParams ile ID alacağız
import axios from 'axios';
import '../styles/forms.css';
import config from '../config/config';


function SirketDüzenle() {
  const { id } = useParams(); // URL'den ID'yi alıyoruz
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    fullName: '',
    address: '',
    phone: '',
    website: '',
    email: '',
    taxOffice: '',
    TC: '',
    sehir: ''
  });

  // Sayfa yüklendiğinde mevcut şirket bilgilerini çekiyoruz
  useEffect(() => {
    axios.get(`${config.baseUrl}/api/company/${id}`)
      .then(response => {
        setFormData(response.data); // Gelen verileri formData'ya kaydediyoruz
      })
      .catch(error => {
        console.error('Şirket bilgileri alınırken hata oluştu:', error);
      });
  }, [id]); // ID her değiştiğinde bu işlemi yap

  // Formdaki değişiklikleri yönetiyoruz
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleCancel = () => {
    navigate('/sirket-liste');
  };

  // Formu gönderme işlemi
  const handleSubmit = (e) => {
    e.preventDefault();
    axios.put(`${config.baseUrl}/api/company/${id}`, formData)
      .then(() => {
        alert('Şirket başarıyla güncellendi');
        navigate('/sirket-liste'); // Güncelleme sonrası listeye yönlendirme
      })
      .catch(error => {
        console.error('Şirket güncellenirken hata oluştu:', error);
      });
  };

  return (
    <div className="container mt-5">
      <h1>Şirket Düzenleme Formu</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">Şirket</label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            value={formData.name} // Mevcut veriyi formda göster
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="fullName" className="form-label">Ad Soyad</label>
          <input
            type="text"
            className="form-control"
            id="fullName"
            name="fullName"
            value={formData.fullName} // Mevcut veriyi formda göster
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="address" className="form-label">Adres</label>
          <input
            type="text"
            className="form-control"
            id="address"
            name="address"
            value={formData.address} // Mevcut veriyi formda göster
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="phone" className="form-label">Telefon No</label>
          <input
            type="tel"
            className="form-control"
            id="phone"
            name="phone"
            value={formData.phone} // Mevcut veriyi formda göster
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="website" className="form-label">Website</label>
          <input
            type="text"
            className="form-control"
            id="website"
            name="website"
            value={formData.website} // Mevcut veriyi formda göster
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="email" className="form-label">E-posta Adresi</label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={formData.email} // Mevcut veriyi formda göster
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="taxOffice" className="form-label">Vergi Dairesi</label>
          <input
            type="text"
            className="form-control"
            id="taxOffice"
            name="taxOffice"
            value={formData.taxOffice} // Mevcut veriyi formda göster
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="TC" className="form-label">TC No</label>
          <input
            type="text"
            className="form-control"
            id="TC"
            name="TC"
            value={formData.TC} // Mevcut veriyi formda göster
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="sehir" className="form-label">Şehir</label>
          <input
            type="text"
            className="form-control"
            id="sehir"
            name="sehir"
            value={formData.sehir} // Mevcut veriyi formda göster
            onChange={handleChange}
            required
          />
        </div>

        <button type="submit" className="btn custom-btn" style={{ marginRight: '10px' }}>Bilgileri Kaydet</button>
        <button type="button" className="btn btn-secondary" onClick={handleCancel} style={{ backgroundColor: 'red', color: 'white' }}>Vazgeç</button>
        
      </form>
    </div>
  );
}

export default SirketDüzenle;
