import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // useNavigate'i import ediyoruz
import '../styles/forms.css'; // form.css dosyasını import ediyoruz
import axios from 'axios';
import config from '../config/config';


function Register() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '' // Parola onayı alanı eklendi
  });

  const [error, setError] = useState(''); // Hata mesajı durumu için state eklendi
  const [showPassword, setShowPassword] = useState(false); // Parola görünürlüğü için state
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // Parola onayı görünürlüğü için state
  const navigate = useNavigate(); // useNavigate hook'u ile yönlendirme işlemi için kullanıyoruz

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Telefon kısmında yalnızca rakamların girilmesine izin veriyoruz
    if (name === 'phone') {
      const onlyNums = value.replace(/[^0-9]/g, ''); // Sadece rakamları kabul eden bir regex kullanıyoruz
      setFormData({
        ...formData,
        [name]: onlyNums // Telefon alanını sadece rakamlarla güncelliyoruz
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Parolaların eşleşip eşleşmediğini kontrol ediyoruz
    if (formData.password !== formData.confirmPassword) {
      setError('Parolalar eşleşmiyor!'); // Hata mesajını state'e kaydediyoruz
      return;
    }

    // Eğer parolalar eşleşiyorsa, form gönderiliyor
    axios.post(`${config.baseUrl}/api/register`, formData)
      .then(response => {
        console.log('Kayıt başarılı:', response.data);
        navigate('/'); // Kayıt başarılı olduğunda Giriş Yap sayfasına yönlendirme yapılıyor
      })
      .catch(error => {
        console.error('Kayıt hatası:', error);
        setError('Kayıt sırasında bir hata oluştu.'); // Hata oluşursa gösteriliyor
      });
  };

  // Parola görünürlüğünü değiştiren fonksiyon
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    
    <div className="container">
      <h1 style={{ textAlign: 'center' }}>Kayıt Ol</h1> {/* Kayıt Ol başlığı ortalandı */}
      <form
        onSubmit={handleSubmit}
        style={{
          maxWidth: '500px', /* Form genişliğini daralttık */
          margin: '0 auto' /* Ortalamak için margin ayarı */
        }}
      >
        <div className="mb-3">
          <label htmlFor="firstName" className="form-label">Adı</label>
          <input
            type="text"
            className="form-control"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="lastName" className="form-label">Soyadı</label>
          <input
            type="text"
            className="form-control"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="email" className="form-label">Mail Adresi</label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="phone" className="form-label">Telefon</label>
          <input
            type="tel"
            className="form-control"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="password" className="form-label">Parola</label>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type={showPassword ? 'text' : 'password'} // Parola görünürlüğü
              className="form-control"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
            <button type="button" onClick={toggleShowPassword} style={{ marginLeft: '10px' }}>
              {showPassword ? 'Gizle' : 'Göster'} {/* Göz simgesi yerine metin kullandık */}
            </button>
          </div>
        </div>

        <div className="mb-3">
          <label htmlFor="confirmPassword" className="form-label">Parolayı Onayla</label>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type={showConfirmPassword ? 'text' : 'password'} // Parola onay görünürlüğü
              className="form-control"
              id="confirmPassword"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
            />
            <button type="button" onClick={toggleShowConfirmPassword} style={{ marginLeft: '10px' }}>
              {showConfirmPassword ? 'Gizle' : 'Göster'}
            </button>
          </div>
        </div>

        {/* Hata mesajı */}
        {error && <p style={{ color: 'red' }}>{error}</p>}

        <button type="submit" className="custom-btn">Kayıt Ol</button>
      </form>

      <div style={{ textAlign: 'center', marginTop: '20px' }}> {/* Giriş Yap butonunu formun altına aldık */}
        <Link to="/" className="btn" style={{ height: 'fit-content' }}>Giriş Yap</Link>
      </div>
    </div>
  );
}

export default Register;
