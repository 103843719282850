import React, { useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom'; // Token'ı URL'den almak için
import config from '../config/config';


function ResetPassword() {
  const { token } = useParams(); // URL'den token'ı al
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert('Şifreler eşleşmiyor!');
      return;
    }

    axios.post(`${config.baseUrl}/api/reset-password/${token}`, { password })
      .then(response => {
        alert('Şifreniz başarıyla sıfırlandı. Giriş yapabilirsiniz.');
        navigate('/'); // Şifre sıfırlama başarılıysa giriş sayfasına yönlendir
      })
      .catch(error => {
        console.error('Şifre sıfırlama hatası:', error);
        alert('Şifre sıfırlama sırasında bir hata oluştu.');
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Yeni Şifre</label>
        <input 
          type="password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
          required 
        />
      </div>
      <div>
        <label>Şifreyi Onayla</label>
        <input 
          type="password" 
          value={confirmPassword} 
          onChange={(e) => setConfirmPassword(e.target.value)} 
          required 
        />
      </div>
      <button type="submit">Şifreyi Sıfırla</button>
    </form>
  );
}

export default ResetPassword;
