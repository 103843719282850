import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../styles/forms.css'; // Mevcut CSS dosyasını import ediyoruz
import config from '../config/config';


function Belgeler() {
  const [invoice, setInvoice] = useState([]);
  const [finalizedInvoices, setFinalizedInvoices] = useState([]); // finalized_invoices için state tanımlıyoruz

  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`${config.baseUrl}/api/belgeler`)
      .then(response => {
        console.log("Gelen veriler:", response.data);
        setInvoice(response.data);
      })
      .catch(error => {
        console.error('Veriler çekilirken hata oluştu:', error);
      });
  }, []);

  // Tarih formatlama fonksiyonu
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  const handleView = (id) => {
    const invoice = finalizedInvoices.find((invoice) => invoice.originalId === id); // orijinal id ile finalized tabloyu bul
    const finalizedId = invoice ? invoice.id : id; // Eğer finalized fatura bulunduysa onun id'sini al
    const url = `/biten/${finalizedId}`; // finalized tablosundaki id ile yönlendirme
    window.open(url, '_blank'); // Yeni sekmede aç
  };



  const handleVieww = (id) => {
    const url = `/belge/${id}`; // biten/id sayfasına yönlendirme
    window.open(url, '_blank'); // Yeni sekmede aç
  };

  const handleEdit = (id) => {
    navigate(`/fatura-duzenle/${id}`);
  };

  const handleStatusChange = (id) => {
    if (window.confirm("Bu faturayı 'kesildi' durumuna getirmek istiyor musunuz?")) {
      axios.put(`${config.baseUrl}/api/invoice/finalize/${id}`, { status: 'kesildi' })
        .then(() => {
          setInvoice(prevInvoices => prevInvoices.map(invoice =>
            invoice.id === id ? { ...invoice, status: 'kesildi' } : invoice
          ));
        })
        .catch(error => {
          console.error('Durum değişikliği sırasında hata oluştu:', error);
        });
    }
  };

  const handleDelete = (id) => {
    if (window.confirm("Bu faturayı silmek istediğinizden emin misiniz?")) {
      axios.put(`${config.baseUrl}/api/invoice/${id}`, { isActive: false })
        .then(() => {
          alert("Fatura başarıyla silindi.");
          setInvoice(prevInvoices => prevInvoices.map(invoice =>
            invoice.id === id ? { ...invoice, isActive: false } : invoice
          ));
        })
        .catch(error => {
          console.error('Fatura silinirken hata oluştu:', error);
        });
    }
  };


  // Sadece aktif ve finalize edilmemiş faturaları listeleyelim
  const activeInvoices = invoice.filter(item => item.isActive);

  return (
    <div className="container">
      <div className="header-with-buttons">
        <h1 className="list-header">Fatura Listesi</h1>
        <div className="button-group">
          <Link to="/fatura-sil" className="btn">Silinenler</Link>
          <Link to="/fatura-ekle" className="btn">Fatura Ekle</Link>
          <Link to="/anasayfa" className="btn">Ana Sayfa</Link>
        </div>
      </div>

      <table className="table">
        <thead>
          <tr>
            <th>#</th>
            <th>Fatura Kesen</th>
            <th>Müşteri</th>
            <th>Toplam Tutar</th>
            <th>Tarih</th>
            <th>Belgeyi Gör</th>
            <th>Aksiyonlar</th>
          </tr>
        </thead>
        <tbody>
          {activeInvoices.map(item => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.invoicerName}</td>
              <td>{item.customerName}</td>
              <td>{item.faturaTutar}</td>
              <td>{formatDate(item.date)}</td>
              <td>
                {item.status !== 'kesildi' ? (
                  <button
                    className="action-btn status-btn"
                    onClick={() => handleStatusChange(item.id)}
                  >
                    KES
                  </button>
                ) : (









                  <button className="btn view-btn" style={{ padding: '3px 6px', fontSize: '0.9rem' }} onClick={() => handleView(item.id)}>GÖR</button>














                  
                )}
              </td>
              <td>
                {item.status !== 'kesildi' ? (
                  <>
                    <button className="action-btn edit-btn" onClick={() => handleEdit(item.id)}>Düzenle</button>
                    <button className="action-btn delete-btn" onClick={() => handleDelete(item.id)}>Sil</button>
                    <button className="action-btn preview-btn" onClick={() => handleVieww(item.id)}>Önizle</button>
                  </>
                ) : (
                  <span>İşlem Yapılamaz</span>
                )}
              </td>

            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Belgeler;
