import React, { useState, useEffect } from 'react';
import '../styles/forms.css'; // Mevcut CSS dosyasını import ediyoruz
import { Link, useNavigate } from 'react-router-dom'; // useNavigate'i import ediyoruz
import axios from 'axios';
import config from '../config/config';


function HizmetEkle() {
  const [formData, setFormData] = useState({});
  const navigate = useNavigate(); // useNavigate'i başlatıyoruz

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0'); // Gün
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Ay
    const year = date.getFullYear(); // Yıl
    const hours = String(date.getHours()).padStart(2, '0'); // Saat
    const minutes = String(date.getMinutes()).padStart(2, '0'); // Dakika

    return `${day}-${month}-${year} ${hours}:${minutes}`;  // Tarih ve saat formatı
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };



  useEffect(() => {
    const quantity = parseFloat(formData.quantity || 0);
    const unitPrice = parseFloat(formData.unitPrice || 0);
    const discountRate = parseFloat(formData.discountRate || 0);
    const taxPercentage = parseFloat(formData.taxPercentage || 0);
    const otherTax = parseFloat(formData.otherTax || 0);

    // Adet * Birim Fiyat
    let totalBeforeDiscount = quantity * unitPrice;

    // İskonto Hesabı
    let discountAmount = (totalBeforeDiscount * discountRate) / 100;
    let discountedPrice = totalBeforeDiscount - discountAmount;

    // KDV Hesabı
    let taxPrice = (discountedPrice * taxPercentage) / 100;

    // Diğer Vergiler
    let totalAfterTaxes = discountedPrice + taxPrice + otherTax;

    // Form verilerini otomatik güncelle
    setFormData((prevFormData) => ({
      ...prevFormData,
      discountAmount: discountAmount.toFixed(2), // İskonto tutarı
      taxPrice: taxPrice.toFixed(2), // KDV tutarı
      totalPrice: totalAfterTaxes.toFixed(2) // Toplam tutar
    }));
  }, [formData.quantity, formData.unitPrice, formData.discountRate, formData.taxPercentage, formData.otherTax]);




  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form verileri:', formData);
    sendData(formData);
  };

  function sendData(formData) {
    axios.post(`${config.baseUrl}/api/create-content`, formData)
      .then(response => {
        console.log(response);
        navigate('/hizmet-liste'); // Başarıyla kaydedildikten sonra yönlendirme yap
      })
      .catch(error => console.error('Veri gönderme hatası:', error));
  }

  const [invoice, setInvoice] = useState([]);

  useEffect(() => {
    // Axios ile API isteği yapıyoruz
    axios.get(`${config.baseUrl}/api/invoice-list`)  // Backend API yoluna istek
      .then(response => {
        const activeInvoices = response.data.filter(item => item.isActive && item.status !== 'kesildi'); // isActive true ve status kesildi olmayanları filtrele
        setInvoice(activeInvoices);  // Filtrelenmiş verileri state'e kaydediyoruz
      })
      .catch(error => {
        console.error('Veriler çekilirken hata oluştu:', error);
      });
  }, []);

  return (
    <div className="container mt-5">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h1>Hizmet Ekleme Formu</h1>
        <div>
          <Link to="/hizmet-liste" className="btn me-2" style={{ height: 'fit-content' }}>Listele</Link>
          <Link to="/anasayfa" className="btn" style={{ height: 'fit-content' }}>Ana Sayfa</Link>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="hizmetTanimi" className="form-label">Hizmet Tanımı</label>
          <input
            type="text"
            className="form-control"
            id="hizmetTanimi"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="adeti" className="form-label">Adeti</label>
          <input
            type="number"
            className="form-control"
            id="adeti"
            name="quantity"
            value={formData.quantity}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="adetFiyati" className="form-label">Adet Fiyatı</label>
          <input
            type="number"
            className="form-control"
            id="adetFiyati"
            name="unitPrice"
            value={formData.unitPrice}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="iskontoOrani" className="form-label">Iskonto Oranı (%)</label>
          <input
            type="number"
            className="form-control"
            id="iskontoOrani"
            name="discountRate"
            value={formData.discountRate}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="iskontoTutari" className="form-label">Iskonto Tutarı</label>
          <input
            type="number"
            className="form-control"
            id="iskontoTutari"
            name="discountAmount"
            value={formData.discountAmount}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="iskontoArtirimNedeni" className="form-label">Iskonto Artırım Nedeni</label>
          <input
            type="text"
            className="form-control"
            id="iskontoArtirimNedeni"
            name="discountIR"
            value={formData.discountIR}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="kdvOrani" className="form-label">KDV Oranı (%)</label>
          <input
            type="number"
            className="form-control"
            id="kdvOrani"
            name="taxPercentage"
            value={formData.taxPercentage}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="kdvTutari" className="form-label">KDV Tutarı</label>
          <input
            type="number"
            className="form-control"
            id="kdvTutari"
            name="taxPrice"
            value={formData.taxPrice}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="digerVergiler" className="form-label">Diğer Vergiler</label>
          <input
            type="number"
            className="form-control"
            id="digerVergiler"
            name="otherTax"
            value={formData.otherTax}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="toplamTutar" className="form-label">Toplam Tutar</label>
          <input
            type="number"
            className="form-control"
            id="toplamTutar"
            name="totalPrice"
            value={formData.totalPrice}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="gtip" className="form-label">GTIP</label>
          <input
            type="text"
            className="form-control"
            id="gtip"
            name="GTIP"
            value={formData.GTIP}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="invoiceId" className="form-label">Fatura Tarihi</label>
          <select
            className="form-control"
            id="invoiceId"
            name="invoiceID"
            value={formData.invoiceID}
            onChange={handleChange}
            required
          >
            <option value="">Fatura Seçin</option>
            {invoice.map(item => (
              <option key={item.id} value={item.id}>
                {`ID: ${item.id} - Tarih: ${formatDate(item.date)}`}
              </option>
            ))}
          </select>
        </div>

        <button type="submit" className="btn custom-btn">Bilgileri Kaydet</button>
      </form>
    </div>
  );
}

export default HizmetEkle;

