import React, { useEffect } from 'react';
import axios from 'axios';


import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Anasayfa from './pages/anasayfa';
import SirketEkle from './pages/sirket';
import HizmetEkle from './pages/hizmet';
import FaturaEkle from './pages/fatura';
import Belgeler from './pages/belgeler';
import Belge from './pages/belge';
import SirketList from './pages/SirketList';
import HizmetList from './pages/HizmetList';
import SirketDuzenle from './pages/SirketDuzenle';
import HizmetDuzenle from './pages/HizmetDuzenle';
import FaturaDuzenle from './pages/FaturaDuzenle';
import Biten from './pages/biten';
import Register from './pages/Register';
import Login from './pages/Login';
import SirketSil from './pages/SirketSil';
import FaturaSil from './pages/FaturaSil';
import HizmetSil from './pages/HizmetSil';
import ForgetPassword from './pages/ForgetPassword';
import ResetPassword from './pages/ResetPassword';

// Axios varsayılan Authorization başlığı ayarı (token her istekte gönderilecek)
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;

// Kullanıcının login olup olmadığını kontrol eden fonksiyon
const isAuthenticated = () => {
  return !!localStorage.getItem('token'); // Token var mı yok mu kontrol ediyoruz
};


// Sayfa başlıklarını dinamik olarak değiştiren fonksiyon
const usePageTitle = () => {
  const location = useLocation(); // Location artık Router içinde

  useEffect(() => {
    // Rotaya göre başlığı ayarlıyoruz
    switch (location.pathname) {
      case '/anasayfa':
        document.title = 'Ana Sayfa';
        break;
      case '/hizmet-ekle':
        document.title = 'Hizmet Ekle';
        break;
      case '/fatura-ekle':
        document.title = 'Fatura Ekle';
        break;
      case '/sirket-ekle':
        document.title = 'Şirket Ekle';
        break;
      case '/belgeler':
        document.title = 'Belgeler';
        break;
      case '/belge/:id':
        document.title = 'Fatura Önizleme';
        break;
      case '/sirket-liste':
        document.title = 'Şirket Listesi';
        break;
      case '/hizmet-liste':
        document.title = 'Hizmet Listesi';
        break;
      case '/sirket-duzenle/:id':
        document.title = 'Şirket Düzenleme';
        break;
      case '/hizmet-duzenle/:id':
        document.title = 'Hizmet Düzenleme';
        break;
      case '/fatura-duzenle/:id':
        document.title = 'Hizmet Düzenleme';
        break;
      case '/biten/:id':
        document.title = 'Fatura Görüntüleme';
        break;
      case '/sirket-sil':
        document.title = 'Silinen Şirketler';
        break;
      case '/fatura-sil':
        document.title = 'Silinen Faturalar';
        break;
      case '/hizmet-sil':
        document.title = 'Silinen Hizmetler';
        break;
      case 'reset-password/:token':
        document.title = 'Şifre Belirleme';
        break;
      case '/forget-password':
        document.title = 'Şifremi Unuttum';
        break;

      case '/register':
        document.title = 'Kayıt Ol';
        break;
      case '/':
        document.title = 'Giriş Yap';
        break;
      default:
        document.title = 'Fatura Uygulaması';
    }
  }, [location]); // location değiştikçe başlığı yeniden ayarlıyoruz
};

function App() {
  return (
    <React.Fragment>
      <Router>
        <PageTitleWrapper /> {/* Dinamik başlık ayarlayıcı burada */}
        <Routes>
          {/* Eğer login olmuşsa yönlendir, değilse login sayfasına yönlendir */}
          <Route path='/anasayfa' element={isAuthenticated() ? <Anasayfa /> : <Navigate to="/" />} />
          <Route path='/hizmet-ekle' element={isAuthenticated() ? <HizmetEkle /> : <Navigate to="/" />} />
          <Route path='/fatura-ekle' element={isAuthenticated() ? <FaturaEkle /> : <Navigate to="/" />} />
          <Route path='/sirket-ekle' element={isAuthenticated() ? <SirketEkle /> : <Navigate to="/" />} />
          <Route path='/belgeler' element={isAuthenticated() ? <Belgeler /> : <Navigate to="/" />} />
          <Route path='/belge/:id' element={isAuthenticated() ? <Belge /> : <Navigate to="/" />} />
          <Route path='/sirket-liste' element={isAuthenticated() ? <SirketList /> : <Navigate to="/" />} />
          <Route path='/hizmet-liste' element={isAuthenticated() ? <HizmetList /> : <Navigate to="/" />} />
          <Route path='/sirket-duzenle/:id' element={isAuthenticated() ? <SirketDuzenle /> : <Navigate to="/" />} />
          <Route path='/hizmet-duzenle/:id' element={isAuthenticated() ? <HizmetDuzenle /> : <Navigate to="/" />} />
          <Route path='/fatura-duzenle/:id' element={isAuthenticated() ? <FaturaDuzenle /> : <Navigate to="/" />} />
          <Route path='/biten/:id' element={isAuthenticated() ? <Biten /> : <Navigate to="/" />} />
          <Route path='/sirket-sil' element={isAuthenticated() ? <SirketSil /> : <Navigate to="/" />} />
          <Route path='/fatura-sil' element={isAuthenticated() ? <FaturaSil /> : <Navigate to="/" />} />
          <Route path='/hizmet-sil' element={isAuthenticated() ? <HizmetSil /> : <Navigate to="/" />} />
          {/* Login ve Register sayfalarına token gerekmiyor */}
          <Route path='/reset-password/:token' element={<ResetPassword />} />
          <Route path='/forget-password' element={<ForgetPassword />} />
          <Route path='/Register' element={<Register />} />
          <Route path='/' element={<Login />} />
        </Routes>
      </Router>
    </React.Fragment>
  );
}

const PageTitleWrapper = () => {
  usePageTitle(); // Sayfa başlığı dinamik olarak ayarlanıyor
  return null; // Bu bileşen görünmezdir, sadece başlık için çalışır
};

export default App;
