import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from '../config/config';



function HizmetSil() {
  const [deletedContents, setDeletedContents] = useState([]);

  // API'den silinen Hizmetleri getiriyoruz
  useEffect(() => {
    axios.get(`${config.baseUrl}/api/content-deleted`)  // isActive = false olanları al
      .then(response => {
        console.log("Silinen Hizmetler: ", response.data); // Gelen verileri kontrol ediyoruz
        setDeletedContents(response.data);
      })
      .catch(error => {
        console.error('Veriler çekilirken hata oluştu:', error);
      });
  }, []);

  // Hizmeti geri getirme fonksiyonu
  const handleRestore = (id) => {
    if (window.confirm('Bu Hizmeti geri getirmek istediğinize emin misiniz?')) {
      axios.put(`${config.baseUrl}/api/content/restore/${id}`, { isActive: true })
        .then(() => {
          // Hizmeti geri getirince listeden çıkarıyoruz
          setDeletedContents(deletedContents.filter(content => content.id !== id));
        })
        .catch(error => {
          console.error('Geri getirme hatası:', error);
        });
    }
  };

  return (
    <div className="container">
      <div className="header-with-buttons">
        <h1 className="list-header">Silinen Hizmetler</h1>
        <Link to="/hizmet-liste" className="btn">Hizmet Listesi</Link>
      </div>

      {/* Hizmetleri tablo halinde gösteriyoruz */}
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Hizmet Tanımı</th>
            <th>Adet</th>
            <th>Adet Fiyatı</th>
            <th>İskonto %</th>
            <th>KDV %</th>
            <th>Ek Vergi Tutarı</th>
            <th>Toplam Tutar</th>
            <th>Aksiyonlar</th>
          </tr>
        </thead>
        <tbody>
          {deletedContents.length === 0 ? (
            <tr>
              <td colSpan="4" style={{ textAlign: 'center' }}>Silinen Hizmet bulunamadı</td>
            </tr>
          ) : (
            deletedContents.map(content => (
              <tr key={content.id}>
                <td>{content.id}</td>
                <td>{content.name}</td>
                <td>{content.quantity}</td>
                <td>{content.unitPrice}</td>
                <td>{content.discountRate}</td>
                <td>{content.taxPercentage}</td>
                <td>{content.otherTax}</td>
                <td>{content.totalPrice}</td>
                <td>
                  <button className="action-btn restore-btn" onClick={() => handleRestore(content.id)}>
                    Geri Getir
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
}

export default HizmetSil;
