import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from '../config/config';


function FaturaSil() {
  const [deletedInvoices, setDeletedInvoices] = useState([]);

  // Tarih formatlama fonksiyonu
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  // API'den silinen Faturaları getiriyoruz
  useEffect(() => {
    axios.get(`${config.baseUrl}/api/invoice-deleted`)  // isActive = false olanları al
      .then(response => {
        console.log("Silinen Faturalar: ", response.data); // Gelen verileri kontrol ediyoruz
        setDeletedInvoices(response.data);
      })
      .catch(error => {
        console.error('Veriler çekilirken hata oluştu:', error);
      });
  }, []);

  // Faturayı geri getirme fonksiyonu
  const handleRestore = (id) => {
    if (window.confirm('Bu Faturayı geri getirmek istediğinize emin misiniz?')) {
      axios.put(`${config.baseUrl}/api/invoice/restore/${id}`, { isActive: true })
        .then(() => {
          // Faturayı geri getirince listeden çıkarıyoruz
          setDeletedInvoices(deletedInvoices.filter(invoice => invoice.id !== id));
        })
        .catch(error => {
          console.error('Geri getirme hatası:', error);
        });
    }
  };

  return (
    <div className="container">
      <div className="header-with-buttons">
        <h1 className="list-header">Silinen Faturalar</h1>
        <Link to="/belgeler" className="btn">Fatura Listesi</Link>
      </div>

      {/* Faturaları tablo halinde gösteriyoruz */}
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>No</th>
            <th>Tarih</th>
            <th>ETTN</th>
            <th>Not</th>
            <th>Fatura Kesen ID</th>
            <th>Müşteri ID</th>
            <th>Aksiyonlar</th>
          </tr>
        </thead>
        <tbody>
          {deletedInvoices.length === 0 ? (
            <tr>
              <td colSpan="8" style={{ textAlign: 'center' }}>Silinen Fatura bulunamadı</td>
            </tr>
          ) : (
            deletedInvoices.map(invoice => (
              <tr key={invoice.id}>
                <td>{invoice.id}</td>
                <td>{invoice.no}</td>
                <td>{formatDate(invoice.date)}</td> {/* Tarih formatlama burada */}
                <td>{invoice.ETTN}</td>
                <td>{invoice.not}</td>
                <td>{invoice.invoicerID}</td>
                <td>{invoice.customerID}</td>
                <td>
                  <button className="action-btn restore-btn" onClick={() => handleRestore(invoice.id)}>
                    Geri Getir
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
}

export default FaturaSil;
