import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/list.css'; // Ortak CSS dosyasını import ediyoruz
import config from '../config/config';


function HizmetList() {
  const [contents, setContents] = useState([]);

  useEffect(() => {
    // API isteği ile hizmet verilerini çekiyoruz
    axios.get(`${config.baseUrl}/api/contents`) // Hizmetleri getiren backend API yolunuza göre ayarlayın
      .then(response => {
        console.log(response.data);
        setContents(response.data); // Gelen verileri state'e kaydediyoruz
      })
      .catch(error => {
        console.error('Veriler çekilirken hata oluştu:', error);
      });
  }, []);



  const navigate = useNavigate();

  const handleEdit = (id) => {
    navigate(`/hizmet-duzenle/${id}`);
  };

  const handleDelete = (id) => {
    if (window.confirm("Bu hizmeti silmek istediğinize emin misiniz?")) {
      axios.put(`${config.baseUrl}/api/content/delete/${id}`)
        .then(() => {
          alert('Hizmet başarıyla silindi.');
          setContents(contents.filter(content => content.id !== id)); // Sadece aktif olanları günceller
        })
        .catch(error => {
          console.error('Hizmet silinirken hata oluştu:', error);
        });
    }
  };



  return (
    <div className="container">
      <div className="header-with-buttons">
        <h1 className="list-header">Hizmet Listesi</h1>
        <div className="button-group">
          <Link to="/hizmet-sil" className="btn">Silinenler</Link>
          <Link to="/hizmet-ekle" className="btn">Hizmet Ekle</Link>
          <Link to="/anasayfa" className="btn">Ana Sayfa</Link>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Hizmet Adı</th>
            <th>Adet</th>
            <th>Birim Fiyatı</th>
            <th>İskonto Oranı</th>
            <th>İskonto Tutarı</th>
            <th>İskonto Artış Nedeni</th>
            <th>KDV Oranı</th>
            <th>KDV Tutarı</th>
            <th>Diğer Vergiler</th>
            <th>Toplam Tutar</th>
            <th>GTIP</th>
            <th>Fatura ID</th>
            <th>Aksiyonlar</th> {/* Aksiyonlar sütunu */}
          </tr>
        </thead>
        <tbody>
          {contents.map(content => (
            <tr key={content.id}>
              <td>{content.id}</td>
              <td>{content.name}</td>
              <td>{content.quantity}</td>
              <td>{content.unitPrice}</td>
              <td>{content.discountRate}</td>
              <td>{content.discountAmount}</td>
              <td>{content.discountIR}</td>
              <td>{content.taxPercentage}</td>
              <td>{content.taxPrice}</td>
              <td>{content.otherTax}</td>
              <td>{content.totalPrice}</td>
              <td>{content.GTIP}</td>
              <td>{content.invoiceID}</td>
              <td>
                {/* Düzenle ve Sil butonları */}
                <button className="action-btn edit-btn" onClick={() => handleEdit(content.id)}>Düzenle</button>
                <button className="action-btn delete-btn" onClick={() => handleDelete(content.id)}>Sil</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default HizmetList;
